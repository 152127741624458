import * as React from 'react';
import { __ } from '@wordpress/i18n';
import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { languageModalTabFocus, listenerNavArrowFocus } from 'utils/menu';
import FooterInfo from 'components/Layout/Footer/FooterInfo';
import LanguagePicker from 'components/I18n/LanguageSwitcher/Modal';
import { PageSearchForm } from 'components/Search/PageSearchForm';
import { legalLinksLinkItems } from 'utils/links-transformers';
import MenuItem from './MenuItem';

class Menu extends React.Component {
	state = {
		open: false,
		subopen: '',
	};

	targetElement;

	navHamburger;

	constructor(props) {
		super(props);

		this.state = {
			open: false,
			subopen: '',
		};
	}

	componentDidMount() {
		document.addEventListener('keydown', listenerNavArrowFocus);
		document.addEventListener('keydown', languageModalTabFocus);
	}

	componentDidUpdate() {
		document.addEventListener('mouseup', this.closeOpenNavs);
		document.addEventListener('keyup', this.closeOpenNavs);
	}

	showTargetElement = () => {
		disableBodyScroll(this.targetElement);
	};

	hideTargetElement = () => {
		enableBodyScroll(this.targetElement);
	};

	componentWillUnmount() {
		clearAllBodyScrollLocks();
		document.removeEventListener('mouseup', this.closeOpenNavs);
		document.removeEventListener('keyup', this.closeOpenNavs);
	}

	subNavClick = (e, islink) => {
		if (!islink) {
			e.preventDefault();
		}
	};

	subNavFocus = (e, ID, islink) => {
		let newState = '';

		if (!islink) {
			// Open Close Subnav
			const CurrentOpen = this.state.subopen;
			newState = CurrentOpen === ID ? '' : ID;

			this.setState({
				subopen: newState,
			});
		}
	};

	closeOpenNavs = (e) => {
		// can't find the document type in flow..
		// type it as any and prevent any from leaking by
		const doc = document;

		// typing the selected element as HTMLElement
		const menu = doc.querySelector('.primary-menu');

		const menuHasChildrenAndFocus = menu.querySelectorAll(
			'.menu-item-has-children.child-has-focus',
		);

		if (!menu && !menuHasChildrenAndFocus) {
			return;
		}

		const openMenus = menu.querySelectorAll('.menu-item-has-children.child-has-focus');
		const openMenusCount = openMenus.length;

		// if the event is keyup and it was the ESC key
		if (e.type === 'keyup' && e.keyCode === 27) {
			if (openMenusCount) {
				this.setState({
					subopen: '',
				});
			}

			// If the event was a mouseup or enter key
		} else if (
			e.type === 'mouseup' ||
			(e.type === 'keyup' && (e.keyCode === 13 || e.keyCode === 9))
		) {
			// If parent item of subnav element is 'clicked' and subnav already open - let us close it and move focus
			const { target } = e;

			if (target instanceof Element && target && target.parentNode) {
				const parent = target.parentNode;

				let defaultFocus = document.getElementById('site-menu-toggle');

				if (this.props.desktop) {
					defaultFocus = document.getElementById('site-logo');
				}

				if (
					defaultFocus &&
					parent &&
					parent instanceof Element &&
					parent.classList.contains('child-clicked-to-open')
				) {
					parent.classList.remove('child-clicked-to-open');
					this.setState({
						subopen: '',
					});
				} else if (
					defaultFocus &&
					parent &&
					parent instanceof Element &&
					parent.classList.contains('menu-item-has-children')
				) {
					if (!parent.classList.contains('child-has-focus')) {
						const id = parseInt(parent.id.split('menu-item-')[1]);
						this.setState({
							subopen: id,
						});
					}

					parent.classList.add('child-clicked-to-open');
				}

				// If we click on anywhere that is not in menu - let us close menus
				if (!menu.contains(target) && openMenusCount) {
					this.setState({
						subopen: '',
					});
				}
			}
		}
	};

	// eslint-disable-next-line no-unused-vars
	menuLinkClickAction = (e) => {
		// Close navigation
		this.menuToggleAction();
		// Enable body scroll
		enableBodyScroll(this.targetElement);
	};

	menuItems = (menu = []) => {
		return menu.map((menuItem) => (
			<MenuItem
				key={menuItem.ID}
				item={menuItem}
				subnavclick={this.subNavClick}
				subnavfocus={this.subNavFocus}
				subopen={this.state.subopen}
				desktop={this.props.desktop}
				id={this.props.id}
				menuLinkClickAction={this.menuLinkClickAction}
			/>
		));
	};

	menuToggleAction = () => {
		const newState = !this.props.active;

		if (newState) {
			// Body scroll lock
			this.showTargetElement();
		} else {
			if (this.navHamburger && typeof this.navHamburger !== 'undefined') {
				this.navHamburger.focus();
			}

			// Body scroll enable
			this.hideTargetElement();
		}
	};

	menuToggle = (e) => {
		e.preventDefault();

		this.menuToggleAction();
	};

	render() {
		const { primary, region } = this.props.menus;
		const { epConfig } = this.props;
		return (
			<nav
				className="site-navigation site-navigation--desktop"
				itemScope="itemscope"
				itemType="http://schema.org/SiteNavigationElement"
			>
				<div id="menu-main-nav" className="site-navigation__inner">
					<div
						className="site-navigation__drawer"
						ref={(el) => (this.targetElement = el)}
					>
						<ul className="primary-menu">{this.menuItems(primary)}</ul>
						<div className="site-navigation__footer">
							<FooterInfo
								context="site-navigation"
								links={legalLinksLinkItems(this.props.legalLinks)}
							/>

							<PageSearchForm
								key="menu-search"
								epConfig={epConfig}
								placeholder={__('Search', 'starbucks')}
								clearSearchTermOnLeave
							/>

							{process.env.NEXT_PUBLIC_FEATURE_SITE_SELECTOR && (
								<LanguagePicker desktop menuItems={region} menuState={false} />
							)}
						</div>
					</div>
				</div>
			</nav>
		);
	}
}

export default Menu;
