import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { getSocialNetworks } from 'utils/get-social-networks';
import { useRouter } from 'next/router';

/**
 * @todo document this component
 */
const SocialNav = ({ context }) => {
	const { locale } = useRouter();
	const networks = getSocialNetworks(locale);
	const networkNumber = Object.keys(networks).length;

	return (
		<div className={context ? `social-nav social-nav--${context}` : 'social-nav'}>
			<ul
				className={classnames(
					context ? `social-nav__ul social-nav__ul--${context}` : 'social-nav__ul',
					`social-nav__ul--${networkNumber}`,
				)}
			>
				{Object.keys(networks).map((networkKey) => {
					const network = networks[networkKey];
					return (
						network && (
							<li className="social-nav__li" key={networkKey}>
								<a
									className="social-nav__link"
									href={network.url}
									target="_blank"
									rel="noopener noreferrer"
								>
									<span className="screen-reader-text">{network.name}</span>
									<network.icon className="sb-icon social-nav__icon" />
								</a>
							</li>
						)
					);
				})}
			</ul>
		</div>
	);
};

SocialNav.propTypes = {
	context: PropTypes.string,
};

export default SocialNav;
