import FacebookIcon from 'assets/icons/facebook.svg';
import TwitterIcon from 'assets/icons/twitter.svg';
import InstagramIcon from 'assets/icons/instagram.svg';
import YouTubeIcon from 'assets/icons/youtube.svg';
import XIcon from 'assets/icons/x.svg';
import { __ } from '@wordpress/i18n';

export const getSocialNetworks = (locale) => {
    const networks = {
        facebook: {
            name: 'Facebook',
            url: 'https://www.facebook.com/starbucks.stories',
            icon: FacebookIcon,
        },
        twitter: {
            name: 'Twitter',
            url: 'https://twitter.com/Starbucksnews',
            icon: TwitterIcon,
        },
        instagram: {
            name: 'Instagram',
            url: 'https://www.instagram.com/starbucks',
            icon: InstagramIcon,
        },
        youtube: {
            name: 'YouTube',
            url: 'https://www.youtube.com/starbucks',
            icon: YouTubeIcon,
        },
    };

    if (locale === 'emea') {
        networks.facebook.url = 'https://www.facebook.com/starbucksuk';
        networks.twitter.url = 'https://twitter.com/starbucksuk';
        networks.instagram.url = 'https://www.instagram.com/starbucksuk';
    }

    if (locale === 'en-ca' || locale === 'fr') {
        networks.facebook.url = 'https://www.facebook.com/starbuckscanada';
        networks.twitter.url = 'https://twitter.com/starbuckscanada';
        networks.instagram.url = 'https://www.instagram.com/starbuckscanada';
    }

    if (locale === 'br') {
        networks.facebook.url = 'https://www.facebook.com/starbucksbrasil';
        networks.instagram.url = 'https://www.instagram.com/starbucksbrasil';
    }

    if (locale === 'ja') {
        networks.facebook.url = 'https://www.facebook.com/starbucksjapan';
        networks.twitter.url = 'https://twitter.com/starbucks_j';
        networks.instagram.url = 'https://www.instagram.com/starbucks_j';
        networks.youtube.url = 'https://www.youtube.com/user/StarbucksJPN';
        networks.twitter.icon = XIcon;
    }

    return networks;
}