import React, { Component } from 'react';
import { sprintf, __ } from '@wordpress/i18n';

/**
 * @todo document this component
 */
class FooterInfo extends Component {
	/**
	 * @todo document this method
	 * @param {*} context
	 */
	static decorateClasses(context) {
		if (!context) {
			return {
				colophonClass: '',
				colophonContentClass: '',
				legalClass: '',
				legalUlClass: '',
			};
		}
		return {
			colophonClass: ` sb-right-reserved--${context}`,
			colophonContentClass: ` sb-right-reserved__content--${context}`,
			legalClass: ` sb-legal-nav--${context}`,
			legalUlClass: ` sb-legal-nav__ul--${context}`,
		};
	}

	legallinksnav = () => {
		const { legalClass, legalUlClass } = FooterInfo.decorateClasses(this.props.context);

		return (
			<div
				className={`sb-legal-nav${legalClass}`}
				aria-label="privacy and terms"
				role="navigation"
			>
				<ul className={`sb-legal-nav__ul${legalUlClass}`}>
					{this.props.links}
					{this.props.context === 'footer' && (
						<li className="sb-legal-nav__li">
							<div id="teconsent"></div>
						</li>
					)}
				</ul>
			</div>
		);
	};

	colophon = () => {
		const { colophonClass, colophonContentClass } = FooterInfo.decorateClasses(
			this.props.context,
		);

		return (
			<div className={`sb-right-reserved${colophonClass}`}>
				<p className={`sb-right-reserved__content${colophonContentClass}`}>
					{sprintf(
						__('© %d Starbucks Corporation. All rights reserved.', 'starbucks'),
						new Date().getFullYear(),
					)}
				</p>
			</div>
		);
	};

	render() {
		return (
			<>
				{this.legallinksnav()}
				{this.colophon()}
			</>
		);
	}
}

export default FooterInfo;
